<!-- 确认订单页面 -->
<template>
  <div class="orderConfirm center-wrap">
    <div class="big-box">
      <!-- 确认订单 -->
      <div class="shopping-cart">
        <div class="cart-title">
          <div>
            <div v-text="$t('order.confirm')">确认订单</div>
          </div>
        </div>
        <div class="order-address">
          <div class="address-title">
            <p v-text="$t('order.choose_adderss')">选择收货地址</p>

            <div class="search_address_box">
              <div
                class="click"
                @click.stop="addAddressMaskShow = true"
                v-text="$t('order.add_adderss')"
              >新建地址</div>

              <el-input placeholder="收件人/手机号/地址" v-model="searchKeyworld" size="small">
                <template slot="append">
                  <el-button @click="httpMyAddressList">查找地址</el-button>
                </template>
              </el-input>
            </div>
          </div>

          <div v-if="addressListData">
            <template v-for="(item,index) in myAddressList">
              <div class="address" :key="index">
                <img
                  :src="require(`../assets/icon/${item.id == currentAddressId ? 'yes':'no'}-dot.png`)"
                  class="click"
                  @click="currentAddressId = item.id"
                />

                <div>
                  <div>
                    <p class="address-people" v-text="item.name"></p>
                    <p class="address-people phone" v-text="item.mobile"></p>
                    <p class="address-modify">
                      <span
                        class="click"
                        v-text="$t('order.edit')"
                        @click.stop="editAddress(index)"
                      >编辑</span>/
                      <span
                        class="click"
                        v-text="$t('order.del')"
                        @click.stop="removeAddress(index)"
                      >删除</span>
                    </p>
                  </div>

                  <p
                    class="address-detail"
                    v-text="item.province + item.city + item.area + item.address"
                  ></p>
                </div>
              </div>
            </template>

            <div class="pagination_box">
              <el-pagination
                small
                :hide-on-single-page="true"
                background
                layout="prev, pager, next"
                :total="addressListData.total"
                :page-size="addressListData.per_page"
                :current-page="addressListData.current_page"
                @current-change="httpMyAddressList"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>

      <!-- 确认商品信息 -->
      <div v-if="order">
        <h1 v-text="$t('order.confirm_product')">确认商品信息</h1>

        <div
          class="my-cart"
          v-for="(orderBranch,orderBranchIndex) in order"
          :key="orderBranchIndex"
        >
          <div class="shopping-cart">
            <div class="cart-title">
              <div>
                <div v-text="$t('order.freight')">运费</div>
                <p class="transport">{{common.showPrice(orderBranch.freight,userinfo.local)}}</p>
              </div>
              <div>
                <div v-text="$t('order.product_amount')">商品金额</div>
                <p class="all-money">{{common.showPrice(orderBranch.total,userinfo.local)}}</p>
              </div>
            </div>
            <div
              class="order"
              v-for="(orderItem,orderItemIndex) in orderBranch.info"
              :key="orderItemIndex"
            >
              <img
                :src="orderItem.image"
                class="book-photo click"
                @click.stop="toProductDetailPage(orderItem.goods_id)"
              />
              <div class="parameter-body">
                <div class="name-box">
                  <p class="click">{{orderItem.goods_name}}</p>
                  <div class="name-box-add" v-if="orderItem.discount_text">
                    <div>{{orderItem.discount_text}}</div>
                  </div>
                </div>
                <p>{{orderItem.name}}</p>
                <div class="much">
                  <div>{{common.showPrice(orderItem.price,orderItem.price_type)}}</div>
                  <!-- 商品币种和用户币种不一致 显示转化价格 -->
                  <!-- 港币转人民币 -->
                  <p
                    v-if="orderItem.price_type != userinfo.local && userinfo.local == 1"
                  >{{common.showPrice(orderItem.price_rmb,userinfo.local)}}</p>
                  <!-- 人民币转港币 -->
                  <p
                    v-if="orderItem.price_type != userinfo.local && userinfo.local == 2"
                  >{{common.showPrice(orderItem.price_hk,userinfo.local)}}</p>
                </div>
                <div style="display:flex;flex-flow: column;align-items: center;">
                  <div class="reduce-add">
                    <el-input-number
                      v-model="orderItem.number"
                      :min="1"
                      :max="999999"
                      size="mini"
                      @change="fnSaveItemNumber(orderItem.id,orderItem.number)"
                    ></el-input-number>
                  </div>
                </div>
              </div>
              <img
                src="../assets/icon/close2.png"
                class="item-close"
                @click.stop="fnRemoveProductItem(orderItem.id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="discount-code">
        <p v-text="$t('two.discount_code')">优惠码</p>
        <input
          type="text"
          :placeholder="$t('two.input_discount_code')"
          v-model.trim="discountCode"
          @blur="discountCodeChange()"
        />
      </div>
      <!-- 满减优惠 -->
      <div class="preferential-box" v-if="total">
        <!-- {{total}} -->
        <div>
          <div class="preferential">
            <div>
              <h3 v-text="$t('order.product_total')">商品合计:</h3>
              <p>{{common.showPrice(total.allTotal,userinfo.local)}}</p>
            </div>
            <!-- 运费 -->
            <div v-if="currentAddressId">
              <h3 v-text="$t('order.freight')">运费:</h3>
              <p>{{common.showPrice(total.allFreightTotal,userinfo.local)}}</p>
            </div>

            <div v-if="total.discount_text_arr && total.discount_text_arr.length">
              <h3 v-text="$t('order.discount')">优惠：</h3>
              <div class="preferential-add">
                <div v-for="item in total.discount_text_arr" :key="item.id">
                  <div>{{item.text | showDiscountName}}</div>
                  <p>- {{common.showPrice(item.money,userinfo.local)}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="preferential-money">
            <h3 v-text="$t('order.total')">合计：</h3>
            <p>{{common.showPrice(total.payTotal,userinfo.local)}}</p>
          </div>
        </div>
      </div>
      <!-- 选择支付方式 -->
      <div v-if="total">
        <div class="mode-payment">
          <div class="cart-title">
            <div>
              <div v-text="$t('order.choose_pay')">选择支付方式</div>
            </div>
          </div>
          <div class="log-box">
            <div class="click" @click="payType = 'wxpay'" v-show="wxPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'wxpay'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/wx.png" class="logo" />
              <p v-text="$t('order.wxpay')">微信支付</p>
            </div>
            <div class="click" @click="payType = 'alipay'" v-show="aliPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'alipay'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/zfb.png" class="logo" />
              <p v-text="$t('order.alipay')">支付宝支付</p>
            </div>
            <div class="click" @click="payType = 'balance'" v-show="balancePayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'balance'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/balance.png" class="logo" />
              <div class="balance-value">
                <p>{{$t('order.balance_pay')}}</p>
                <p>¥ {{balance}}</p>
              </div>
            </div>
            <div class="click" @click="payType = 'transfer'" v-show="transferPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'transfer'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/transfer.png" class="logo" />
              <p v-text="$t('order.transfer')">对公转账</p>
            </div>

            <div class="click" @click="payType = 'creditCard'" v-show="creditCardPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'creditCard'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/xyk.png" class="logo" />
              <p v-text="$t('order.creditCard')">信用卡</p>
            </div>

            <div class="click" @click="payType = 'pinganPay'" v-show="pinganPayEnable">
              <img src="../assets/icon/yes-dot.png" class="dot" v-if="payType == 'pinganPay'" />
              <img src="../assets/icon/no-dot.png" class="dot" v-else />
              <img src="../assets/icon/pingan.png" class="logo" />
              <p v-text="$t('order.pinganPay')">平安支付</p>
            </div>
          </div>
        </div>
        <!-- <p class="explain">{{$t('order.explain_before')}}{{pay_max/1}}{{$t('order.explain_after')}}</p> -->
        <!-- <p class="explain" v-if="aliPayEnable || creditCardPayEnable">使用Alipay或信用卡支付可能產生額外手續費。</p> -->
      </div>
      <!-- 需要开发票 只有大陆用户才有此功能-->
      <div class="mode-payment" v-if="userinfo && userinfo.local == 1">
        <div class="invoice-title">
          <div>
            <div class="click">
              <img
                src="../assets/icon/yes-select-two.png"
                v-if="useBill"
                @click.stop="useBill = false;"
              />
              <img src="../assets/icon/no-select-two.png" v-else @click.stop="useBill = true;" />
            </div>
            <p class="invoice click" v-text="$t('order.use_invoice')">需要开发票</p>
          </div>
          <div class="click" @click.stop="invoiceMaskShow = true" v-if="useBill">
            <p class="invoice-blue" v-text="$t('order.add_invoice')">新建发票信息</p>
            <img src="../assets/icon/add-blue.png" />
          </div>
        </div>
        <div v-if="useBill && myBillList">
          <div class="invoice-body" v-for="(item,index) in myBillList" :key="index">
            <img src="../assets/icon/yes-dot.png" v-if="currentbillId == item.id" />
            <img src="../assets/icon/no-dot.png" v-else @click.stop="currentbillId = item.id" />
            <div>
              <div>
                <p v-text="item.title"></p>
                <p>{{$t('order.type')}}：{{item.bill_type_text}}</p>
              </div>
              <p v-text="item.number" v-if="item.type == 1"></p>
            </div>
            <p>
              <span class="click" v-text="$t('order.edit')" @click.stop="fnEditBill(index)">编辑</span>/
              <span class="click" v-text="$t('order.del')" @click.stop="removeBill(index)">删除</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 备注 -->
      <div class="note">
        <p>{{$t('order.order_remark')}}({{$t('order.optional')}})</p>
        <div>
          <textarea maxlength="50" v-model="orderRemark"></textarea>
        </div>
      </div>

      <!-- 结算 -->
      <div class="payment" v-if="total && userinfo">
        <div class="all"></div>
        <div class="payment-botton">
          <div>
            <p>{{common.showPrice(common.add(total.payTotal,orderPayServiceFee),userinfo.local)}}</p>
            <div>{{$t('order.service_fee')}}:{{common.showPrice(orderPayServiceFee,userinfo.local)}}</div>
          </div>
          <p class="click" @click="orderSettle" v-text="$t('order.settle')">结算</p>
        </div>
      </div>
    </div>

    <div class="wechat-qrcode-box" v-if="showWechatPayMask">
      <div class="wechat-qrcode-content">
        <img src="../assets/icon/icon-close.png" class="close-icon" @click.stop="cancelPay" />
        <p v-text="$t('order.wxpay')">微信支付</p>
        <div class="qecode-box" ref="qrcode"></div>
      </div>
    </div>

    <el-dialog title="搜索收货地址" :visible.sync="searchAddressMaskShow">
      <div>
        <div style="margin-top: 15px;">
          <el-input placeholder="请输入收件人/手机号/地址" v-model="searchKeyworld">
            <template slot="append">
              <el-button>搜索</el-button>
            </template>
          </el-input>
        </div>

        <div class="address" v-for="(item,index) in myAddressList" :key="index">
          <img src="../assets/icon/yes-dot.png" class="click" v-if="item.id == currentAddressId" />
          <img
            src="../assets/icon/no-dot.png"
            class="click"
            v-else
            @click.stop="currentAddressId = item.id"
          />
          <div>
            <div>
              <p class="address-people" v-text="item.name">李建</p>
              <p class="address-people phone" v-text="item.mobile">139 8129 3123</p>
              <p class="address-modify">
                <span class="click" v-text="$t('order.edit')" @click.stop="editAddress(index)">编辑</span>/
                <span class="click" v-text="$t('order.del')" @click.stop="removeAddress(index)">删除</span>
              </p>
            </div>
            <p class="address-detail" v-text="item.province + item.city + item.area + item.address"></p>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 收货地址蒙层 -->
    <v-add-address
      @close="addAddressMaskShow=false"
      v-if="addAddressMaskShow"
      @addAddressSuccess="addAddressSuccess"
      @editAddressSuccess="editAddressSuccess"
      :editAdderss="editAdderss"
    />

    <!-- 发票蒙层 -->
    <v-invoice-mask
      @close="invoiceMaskShow = false"
      v-if="invoiceMaskShow"
      @addSuccess="addSuccess"
      :editBill="editBill"
      @editSuccess="editBillSuccess"
    ></v-invoice-mask>

    <v-pay-password-mask
      @close="cancelPay"
      @fnForget="handleForget"
      v-if="payPasswordMaskShow"
      @payPasswordSubmit="balancePayOrderSubmit"
    ></v-pay-password-mask>

    <v-set-pay-pwd-mask
      @close="cancelPay"
      v-if="setPasswordMaskShow"
      @setPayPwdSuccess="setPayPwdSuccess"
    ></v-set-pay-pwd-mask>

    <el-dialog
      :title="$t('order.selectCard')"
      :visible.sync="pinganPayDialogVisible"
      width="350px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-select v-model="pinganPayCardValue">
        <el-option label="ICBC 工商银行" value="ICBC" />
        <el-option label="ABC 农业银行" value="ABC" />
        <el-option label="BOC 中国银行" value="BOC" />
        <el-option label="CCB 建设银行" value="CCB" />
        <el-option label="CMB 招商银行" value="CMB" />
        <el-option label="GDB 广发银行" value="GDB" />
        <el-option label="CMBC 民生银行" value="CMBC" />
        <el-option label="CEB 光大银行" value="CEB" />
        <el-option label="HXB 华夏银行" value="HXB" />
        <el-option label="PSBC 邮政储蓄" value="PSBC" />
        <el-option label="BCCB 北京银行" value="BCCB" />
        <el-option label="PAB 平安银行" value="PAB" />
        <el-option label="SPDB 浦发银行" value="SPDB" />
        <el-option label="CZSB 浙商银行" value="CZSB" />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onPinganPayConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { MessageBox, InputNumber, Dialog } from 'element-ui'
import AddAddress from '../components/addAddress.vue'
import InvoiceMask from '../components/invoiceMask.vue'
import PayPasswordMask from '../components/payPasswordMask.vue'
import SetPayPwdMask from '../components/setPayPwdMask.vue'
import QRCode from 'qrcodejs2'
export default {
  name: 'OrderConfirm.vue',
  data() {
    return {
      discountCode: '', //优惠码
      payPasswordMaskShow: false,
      setPasswordMaskShow: false,
      payType: null, // wxpay:微信支付  alipay:支付宝支付 balance:余额支付 transfer:对公转账
      myAddressList: [], // 收货地址所需属性
      addressListData: null, // 收货地址列表数据
      addAddressMaskShow: false,
      searchAddressMaskShow: false,
      searchKeyworld: '',
      currentAddressId: null,
      editAdderss: null,
      editAdderssIndex: null,
      useBill: false, //发票所需属性
      myBillList: null,
      invoiceMaskShow: false,
      currentbillId: null,
      editBill: null,
      editBillIndex: null,
      order: null, // 确认订单数据
      total: null, //总价信息
      orderRemark: '', //订单备注
      exchangerate: localStorage.getItem('exchangerate'), // 币种汇率
      hk_ali_serviceFee: localStorage.getItem('hk_ali_serviceFee'), // 香港支付宝支付手续费
      hk_wx_serviceFee: localStorage.getItem('hk_wx_serviceFee'), // 香港微信支付手续费
      ali_serviceFee: localStorage.getItem('ali_serviceFee'), // 大陆支付宝支付手续费
      wx_serviceFee: localStorage.getItem('wx_serviceFee'), // 大陆微信支付手续费
      paypal_serviceFee: localStorage.getItem('paypal_serviceFee'), // 大陆微信支付手续费
      pay_max: localStorage.getItem('pay_max'), // 支付宝微信最大支付金额
      orderPayServiceFee: 0, // 订单支付所需要的手续费
      userinfo: null,
      showWechatPayMask: false,
      inter: null,
      balance: 0, // 我的账户余额
      order_code: null, // 待支付订单号

      // 选择银行卡弹窗
      pinganPayDialogVisible: false,
      pinganPayCardValue: null,
    }
  },
  computed: {
    // 是否启用微信支付
    wxPayEnable: function () {
      // 大陆和香港用户都隐藏微信支付
      return false
    },
    // 是否启用支付宝支付
    aliPayEnable: function () {
      //大陆用户隐藏支付宝支付 香港用户启用支付宝
      return this.userinfo && this.userinfo.local == 2
    },
    // 是否启用余额支付
    balancePayEnable: function () {
      // 香港用户禁用余额支付 大陆用户启用余额支付
      return this.userinfo && this.userinfo.local == 1
    },
    // 是否启用对公转账支付
    transferPayEnable: function () {
      // 香港用户禁用余额公转账付 大陆用户启用公转账支付
      // return this.userinfo && this.userinfo.local == 1
      return false
    },
    // 是否启用平安支付 替换对公转账
    pinganPayEnable: function () {
      return this.userinfo && this.userinfo.local == 1
    },
    // 是否启用信用卡支付
    creditCardPayEnable: function () {
      // 香港用户可以使用支付宝香港 和信用卡支付
      return this.userinfo && this.userinfo.local == 2
    },
  },
  watch: {
    $route(to, from) {
      var params = this.$route.query.params
      this.httpConfirmOrder(
        params,
        this.currentAddressId ? this.currentAddressId : null
      )
    },

    useBill: function () {
      if (this.useBill && this.myBillList == null) {
        this.httpMyBillList()
      }
    },

    // 监听选择的收货地址变化
    // 每次收货地址变化之后需要重新请求接口 运费会有变化
    currentAddressId: function (newVal, oldVal) {
      var params = this.$route.query.params
      this.httpConfirmOrder(params, this.currentAddressId)
    },

    /*支付方式*/
    payType: function () {
      if (this.payType == 'transfer') {
        this.orderPayServiceFee = 0
      } else if (this.payType == 'wxpay') {
        if (this.userinfo.local == 1)
          this.orderPayServiceFee = this.common.mul(
            this.total.payTotal,
            this.wx_serviceFee
          )
        if (this.userinfo.local == 2)
          this.orderPayServiceFee = this.common.mul(
            this.total.payTotal,
            this.hk_wx_serviceFee
          )
      } else if (this.payType == 'alipay') {
        if (this.userinfo.local == 1)
          this.orderPayServiceFee = this.common.mul(
            this.total.payTotal,
            this.ali_serviceFee
          )
        if (this.userinfo.local == 2)
          this.orderPayServiceFee = this.common.mul(
            this.total.payTotal,
            this.hk_ali_serviceFee
          )
      } else if (this.payType == 'balance') {
        this.orderPayServiceFee = 0
        if (Number(this.balance) < Number(this.total.payTotal)) {
          this.common.showMessage(this.$t('order.balance_bad'), 'error') //余额不足
          this.payType = null
        }
      } else if (this.payType == 'creditCard') {
        this.orderPayServiceFee = this.common.mul(
          this.total.payTotal,
          this.paypal_serviceFee
        )
      }
    },
  },

  created: function () {
    this.httpMyAddressList()
    var userinfo = sessionStorage.getItem('userinfo')
    userinfo = JSON.parse(userinfo)
    if (userinfo) {
      this.userinfo = userinfo
      var params = this.$route.query.params
      this.httpConfirmOrder(params, '')
      this.httpMyBalance()
    } else {
      this.getUserInfo()
    }
  },

  methods: {
    discountCodeChange: function () {
      var params = this.$route.query.params
      this.httpConfirmOrder(
        params,
        this.currentAddressId ? this.currentAddressId : null
      )
    },

    // 移除商品项
    fnRemoveProductItem: function (id) {
      var self = this
      var paramsArray = JSON.parse(this.$route.query.params)
      if (paramsArray.length == 1)
        return this.common.showMessage(this.$t('order.number_min'), 'error') // 最低需要保留一件商品
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: '您確定刪除此商品嗎?', //您确定取消支付该订单吗
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            var index = null
            for (var i = 0; i < paramsArray.length; i++) {
              var item = paramsArray[i]
              if (item.specs_id == id) {
                index = i
                break
              }
            }
            if (index == null) return
            paramsArray.splice(index, 1)
            self.$router.replace({
              path: '/orderConfirm',
              query: {
                params: JSON.stringify(paramsArray),
                cart_id: self.$route.query.cart_id,
              },
            })
          }
        },
      })
    },

    fnChangeItemNumber: function (orderBranchIndex, orderItemIndex, type) {
      var orderBranch = this.order[orderBranchIndex]
      var orderItem = orderBranch.info[orderItemIndex]
      orderItem.showChangeNumber = true
      if (type == 'add') orderItem.number++
      if (type == 'reduce') orderItem.number--
      orderBranch[orderItemIndex] = orderItem
      this.$set(this.order, orderBranchIndex, orderBranch)
    },

    /**
     * 修改数量
     */
    fnSaveItemNumber: function (specs_id, number) {
      if (!number || Number(number) <= 0)
        return this.common.showMessage('数量输入有误', 'error')

      var params = this.$route.query.params
      params = JSON.parse(params)
      var index = -1
      for (var i = 0; i < params.length; i++) {
        var item = params[i]
        if (item.specs_id == specs_id && item.number != number) {
          index = i
          break
        }
      }

      if (index >= 0) {
        params.splice(index, 1, {
          specs_id: specs_id,
          number: Number(number),
        })

        this.$router.replace({
          path: '/orderConfirm',
          query: {
            params: JSON.stringify(params),
            cart_id: this.$route.query.cart_id,
          },
        })
      }
    },

    /**
     * 修改购买商品数量 获取焦点
     */
    inputFocus: function (orderBranchIndex, orderItemIndex) {
      var orderBranch = this.order[orderBranchIndex]
      var orderItem = orderBranch.info[orderItemIndex]
      orderItem.showChangeNumber = true
      orderBranch[orderItemIndex] = orderItem
      this.$set(this.order, orderBranchIndex, orderBranch)
    },

    /**
     * 修改购买商品数量 失去焦点
     */
    inputBlur: function () {},

    httpConfirmOrder(params, address_id) {
      var self = this
      self.common
        .httpPost('/api/order/confirmOrder', {
          orderInfo: params,
          address_id: address_id,
          discount_code: self.discountCode,
        })
        .then((ret) => {
          if (ret.code == 1) {
            // 请求我的收货地址数据
            self.order = ret.data.data
            self.total = ret.data.total
          } else {
            self.common.showMessage(ret.msg, 'error')
            if (ret.code == -100) {
              self.discountCode = ''
              self.discountCodeChange()
            }
          }
        })
    },

    /**
     * 获取用户信息
     */
    getUserInfo() {
      var self = this
      self.common.httpPost('/api/user/getUserInfo').then((ret) => {
        if (ret.code == 1) {
          self.userinfo = ret.data
          sessionStorage.setItem('userinfo', JSON.stringify(ret.data))
          var params = self.$route.query.params
          self.httpConfirmOrder(params)
        }
      })
    },

    handleForget() {
      this.payPasswordMaskShow = false
      this.setPasswordMaskShow = true
    },

    /**
     * 订单结算
     * @return {[type]} [description]
     */
    orderSettle() {
      if (!this.payType)
        return this.common.showMessage(this.$t('order.choice_pay'), 'error') //请选择支付方式
      if (!this.currentAddressId)
        return this.common.showMessage(this.$t('order.choice_address'), 'error') //请选择收货地址
      if (this.useBill && !this.currentbillId)
        return this.common.showMessage(this.$t('order.choice_bill'), 'error') //请选择开票信息

      // if (this.payType == 'wxpay') alert('微信支付')
      // else if (this.payType == 'alipay') alert('支付宝支付')
      // else if (this.payType == "balance") this.payPasswordMaskShow = true
      // else if (this.payType == "transfer") this.$router.push("/transfer")

      var self = this
      self.common
        .httpPost('/api/order/createOrder', {
          discount_code: self.discountCode,
          orderInfo: self.$route.query.params,
          address_id: self.currentAddressId,
          remark: self.orderRemark,
          bill_id: self.currentbillId,
          cart_id: self.$route.query.cart_id ? self.$route.query.cart_id : '',
        })
        .then((ret) => {
          if (ret.code == 1) {
            if (self.$route.query.cart_id) {
              // 重新请求购物车数量
              eventBus.$emit('RefreshCartNumber')
            }
            // 请求我的收货地址数据
            var order_code = ret.data
            self.payOrder(order_code, self.payType)
          } else {
            self.common.showMessage(ret.msg, 'error')
            if (ret.msg == '库存不足') {
              //刷新页面
              setTimeout(function () {
                self.$router.go(0)
              }, 1500)
            }
          }
        })
    },
    /**
     * 支付订单
     * @return {[type]} []
     */
    payOrder: function (order_code, payType) {
      var self = this
      self.order_code = order_code
      if (self.payType == 'wxpay') var pay_type = 1
      if (self.payType == 'alipay') var pay_type = 2
      if (self.payType == 'balance') var pay_type = 3
      if (self.payType == 'transfer') var pay_type = 4
      if (self.payType == 'pinganPay') var pay_type = 5

      if (self.payType == 'alipay' && self.userinfo.local == 1) {
        self.aliPayOrder(order_code)
      } else if (self.payType == 'alipay' && self.userinfo.local == 2) {
        self.aliPayOrderHK(order_code)
      } else if (self.payType == 'wxpay') {
        self.wxPayOrder(order_code)
      } else if (self.payType == 'creditCard') {
        // paypal 信用卡
        self.paypalOrder(order_code)
      } else if (self.payType == 'balance') {
        self.balancePayOrder(order_code)
      } else if (self.payType == 'transfer') {
        self.$router.replace({
          path: '/transfer',
          query: { order_code: order_code },
        })
      } else if (self.payType == 'pinganPay') {
        this.pinganPayDialogVisible = true
      }
    },

    // 余额支付
    balancePayOrder: function (order_code) {
      var self = this
      // 是否设置了支付密码
      self.userHasPayPassword().then((ret) => {
        if (ret.code == 1) {
          if (ret.data == 1) {
            this.payPasswordMaskShow = true
          } else {
            this.setPasswordMaskShow = true
          }
        } else self.common.showMessage(ret.msg, 'error')
      })
    },

    // 使用paypal支付訂單
    paypalOrder: function (order_code) {
      var newWindow = window.open(
        'about:blank',
        '',
        'width=1000,height=850,scrollbars=yes,resizable=1'
      )
      if (newWindow == null || typeof newWindow == 'undefined')
        return alert('关闭您的弹出窗口拦截器！')

      var self = this
      self.common
        .httpPost('/api/ajax/payPalOrder', {
          order_code: order_code,
        })
        .then((ret) => {
          if (ret.code == 1) {
            var url = ret.data
            newWindow.location.href = url

            MessageBox({
              title: self.$t('order.pay_pop'), //提示
              message: self.$t('order.paypal_pay'),
              confirmButtonText: self.$t('order.over_pay'), //完成支付
              showCancelButton: false,
              cancelButtonText: self.$t('order.pay_bad'), //支付遇到问题
              type: 'warning',
              callback(action) {
                clearInterval(self.inter)
                self.$router.replace('/order/list')
              },
            })
          } else {
            newWindow.close()
            self.common.showMessage(ret.msg, 'error')
          }
        })
    },

    /**
     * 余额支付 附带支付密码提交
     */
    balancePayOrderSubmit: function (password) {
      var self = this
      self.common
        .httpPost('/api/order/payOrder', {
          order_code: self.order_code,
          pay_type: 3,
          pay_password: password,
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            self.$router.replace('/order/list')
          }
        })
    },

    // 平安支付
    onPinganPayConfirm: function () {
      var self = this
      if (!this.pinganPayCardValue)
        return this.common.showMessage(this.$t('order.selectCard'), 'error')
      this.pinganPayDialogVisible = false

      var newWindow = window.open(
        'about:blank',
        '',
        'width=1000,height=850,scrollbars=yes,resizable=1'
      )

      if (newWindow == null || typeof newWindow == 'undefined')
        return alert('关闭您的弹出窗口拦截器！')

      var host =
        process.env.NODE_ENV === 'development'
          ? 'http://bookshop.yangkaiq.cn'
          : 'http://' + window.location.host
      var path = `${host}/api/ajax/pingAnOrder?order_code=${this.order_code}&bank_code=${this.pinganPayCardValue}`
      newWindow.location.href = path

      MessageBox({
        title: this.$t('order.pay_pop'), //提示
        message: this.$t('order.bank_pay'), //正在使用支付宝付款...
        confirmButtonText: this.$t('order.over_pay'), //完成支付
        showCancelButton: false,
        cancelButtonText: this.$t('order.pay_bad'), //支付遇到问题
        type: 'warning',
        callback(action) {
          clearInterval(self.inter)
          self.$router.replace('/order/list')
        },
      })
    },

    // 支付寶 支付 香港
    aliPayOrderHK: function (order_code) {
      var newWindow = window.open(
        'about:blank',
        '',
        'width=1000,height=850,scrollbars=yes,resizable=1'
      )

      if (newWindow == null || typeof newWindow == 'undefined')
        return alert('关闭您的弹出窗口拦截器！')

      var self = this
      let routerData = this.$router.resolve({
        path: '/order/alipay_hk',
        query: { order_code: order_code },
      })
      newWindow.location.href = routerData.href

      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.zfb_pay'), //正在使用支付宝付款...
        confirmButtonText: self.$t('order.over_pay'), //完成支付
        showCancelButton: false,
        cancelButtonText: self.$t('order.pay_bad'), //支付遇到问题
        type: 'warning',
        callback(action) {
          clearInterval(self.inter)
          self.$router.replace('/order/list')
        },
      })
    },

    aliPayOrder: function (order_code) {
      var self = this
      var newWindow = window.open(
        'about:blank',
        '',
        'width=1000,height=850,scrollbars=yes,resizable=1'
      )

      if (newWindow == null || typeof newWindow == 'undefined')
        return alert('关闭您的弹出窗口拦截器！')

      newWindow.location.href =
        'https://' +
        window.location.host +
        '/api/ajax/aliPayOrder?order_code=' +
        order_code
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.zfb_pay'), //正在使用支付宝付款...
        confirmButtonText: self.$t('order.over_pay'), //完成支付
        showCancelButton: false,
        cancelButtonText: self.$t('order.pay_bad'), //支付遇到问题
        type: 'warning',
        callback(action) {
          clearInterval(self.inter)
          self.$router.replace('/order/list')
        },
      })
    },

    wxPayOrder: function (order_code) {
      var self = this
      self.common
        .httpPost('/api/order/payOrder', {
          order_code: order_code,
          pay_type: 1,
        })
        .then((ret) => {
          if (ret.code == 1) {
            self.showWechatPayMask = true
            var url = ret.data
            self.$nextTick(function () {
              new QRCode(this.$refs.qrcode, {
                text: url,
                width: 200,
                height: 200,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H,
              })
              setTimeout(function () {
                self.httpLoop(order_code)
              }, 3000)
            })
          } else self.common.showMessage(ret.msg, 'error')
        })
    },
    /**
     * 取消微信支付
     */
    cancelPay: function () {
      var self = this
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.no_pay'), //您确定取消支付该订单吗
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            self.showWechatPayMask = false
            clearInterval(self.inter)

            self.$router.replace('/order/list')
          }
        },
      })
    },
    /**
     * 请求我的余额
     */
    httpMyBalance() {
      var self = this
      this.common.httpPost('/api/user/getMyBalance', {}).then((ret) => {
        if (ret.code == 1) {
          self.balance = ret.data
        }
      })
    },
    /**
     * 是否设置了支付密码
     */
    userHasPayPassword() {
      return this.common.httpPost('/api/user/userHasPayPassword')
    },
    httpLoop: function (order_code) {
      var self = this
      self.inter = setInterval(function () {
        self.common
          .httpPost(
            '/api/user/checkOrderStatus',
            { order_code, order_code },
            false
          )
          .then((ret) => {
            if (ret.code == 1 && ret.data == 1) {
              self.$router.replace({
                path: '/order/list',
              })
              clearInterval(self.inter)
            }
          })
      }, 2000)
    },
    /**
     * 请求我的发票信息列表
     */
    httpMyBillList: function () {
      var self = this
      self.common.httpPost('/api/user/myBillList').then((ret) => {
        if (ret.code == 1) {
          self.myBillList = ret.data
        }
      })
    },
    /**
     * 添加发票
     */
    addSuccess: function (bill) {
      this.invoiceMaskShow = !1
      this.myBillList.push(bill)
    },
    /**
     * 删除发票
     */
    removeBill: function (index) {
      var self = this
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.no_record'), //此操作将永久删除该记录, 是否继续?
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            self.common
              .httpPost('/api/user/delBill', {
                id: self.myBillList[index].id,
              })
              .then((ret) => {
                self.common.showMessage(
                  ret.msg,
                  ret.code == 1 ? 'success' : 'error'
                )
                if (ret.code == 1) self.myBillList.splice(index, 1)
              })
          }
        },
      })
    },
    fnEditBill: function (index) {
      this.editBill = this.myBillList[index]
      this.editBillIndex = index
      this.invoiceMaskShow = true
    },
    editBillSuccess: function (bill) {
      this.invoiceMaskShow = !1
      this.$set(this.myBillList, this.editBillIndex, bill)
      this.editBill = null
      this.editBillIndex = null
    },

    /**
     * 请求我的收货地址
     */
    httpMyAddressList: function (page = 1) {
      var self = this
      page = page || 1

      this.currentAddressId = null

      self.common
        .httpPost('/api/user/myAddressList', {
          page: page,
          keyword_field: 'name|mobile|province|city|area|address%%',
          keyword_value: this.searchKeyworld,
        })
        .then((ret) => {
          if (ret.code == 1) {
            this.addressListData = ret.data
            this.myAddressList = ret.data.data
            this.addressListData.data.forEach((item) => {
              if (item.is_default == 1) {
                this.currentAddressId = item.id
              }
            })
          }
        })
    },

    /**
     * 编辑收货地址
     */
    editAddress(index) {
      this.editAdderss = this.myAddressList[index]
      this.editAdderssIndex = index
      this.addAddressMaskShow = true
    },
    addAddressSuccess(address) {
      this.addAddressMaskShow = !1
      this.myAddressList.push(address)
    },
    /**
     * 编辑收货地址成功
     * @param  {[type]} address
     */
    editAddressSuccess(address) {
      this.addAddressMaskShow = !1
      this.editAdderss = null
      this.$set(this.myAddressList, this.editAdderssIndex, address)
      this.editAdderssIndex = null

      if (address.id == this.currentAddressId) {
        var params = this.$route.query.params
        this.httpConfirmOrder(params, this.currentAddressId)
      }
    },
    /**
     * 删除收货地址
     * @param  {[type]} index [description]
     * @return {[type]}       [description]
     */
    removeAddress(index) {
      var self = this
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.no_record'), //此操作将永久删除该记录, 是否继续?
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            self.common
              .httpPost('/api/user/delAddress', {
                id: self.myAddressList[index].id,
              })
              .then((ret) => {
                self.common.showMessage(
                  ret.msg,
                  ret.code == 1 ? 'success' : 'error'
                )
                if (ret.code == 1) self.myAddressList.splice(index, 1)
              })
          }
        },
      })
    },
    /**
     * 跳转到商品详情页面
     * @param  {[type]} product_id [商品id]
     * @return {[type]}            [description]
     */
    toProductDetailPage(product_id) {
      this.$router.push({
        path: '/product',
        query: {
          product_id: product_id,
        },
      })
    },
    /*设置支付密码成功*/
    setPayPwdSuccess() {
      this.setPasswordMaskShow = false
      this.payPasswordMaskShow = true
    },
  },
  components: {
    'el-input-number': InputNumber,
    'el-dialog': Dialog,
    'v-add-address': AddAddress,
    'v-invoice-mask': InvoiceMask,
    'v-pay-password-mask': PayPasswordMask,
    'v-set-pay-pwd-mask': SetPayPwdMask,
  },
}
</script>
<style scoped>
.big-box {
  margin-left: 83px;
  margin-right: 83px;
  margin-top: 20px;
}

.el-select {
  width: 100%;
}

.wechat-qrcode-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wechat-qrcode-box div {
  background-color: white;
  flex-flow: column;
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wechat-qrcode-box div .qecode-box {
  width: 200px;
  height: 200px;
  margin-top: 20px;
}

.wechat-qrcode-box div .close-icon {
  position: absolute;
  top: -40px;
  width: 30px;
  height: 30px;
  right: 3px;
}

h1 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
  padding: 30px 0 15px 18px;
}

.shopping-cart {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 30px;
}

.cart-title {
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: rgba(247, 246, 246, 1);
}

.cart-title > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-title div div {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 24px;
  margin-right: 10px;
  margin-left: 20px;
}

.transport {
  font-size: 18px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  line-height: 18px;
}

.all-money {
  font-size: 20px;
  /* font-weight: bold; */
  font-family: 'SimHei', 'PingFangSC-Medium', sans-serif;
  color: rgba(225, 36, 27, 1);
  line-height: 20px;
  letter-spacing: 1px;
  margin-right: 30px;
}

.book-name {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 176px;
  margin-right: 190px;
}

.order {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; */
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.order:last-child {
  border-bottom: 0;
}

.choice-botton {
  height: 17px;
  width: 17px;
  margin-right: 23px;
}

.book-photo {
  height: 144px;
  width: 144px;
  margin-right: 60px;
  margin-top: 30px;
  /* border: 0.5px solid #C9C9C9; */
  -o-object-fit: contain;
  object-fit: contain;
  padding-left: 1px;
  padding-bottom: 1px;
}

.delete {
  height: 12px;
  width: 12px;
  margin-left: 128px;
}

.parameter-body {
  /* height: 144px; */
  min-height: 144px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  flex: 1;
}

.name-box p {
  width: 160px;
  margin-right: 93px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
  margin-bottom: 11px;
  text-overflow: ellipsis;
  /*  height: 45px;
    overflow: hidden; */
}

.name-box-add {
  width: 160px;
}

.name-box-add div {
  display: inline-block;
  padding: 3px 6px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  font-size: 12px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
  margin-right: 6px;
  margin-bottom: 6px;
}

.parameter-body > p {
  width: 158px;
  font-size: 14px;
  color: rgba(179, 179, 179, 1);
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  margin-right: 80px;
}

.much {
  width: 70px;
}

.much div {
  text-align: center;
  font-size: 14px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: rgba(63, 63, 63, 1);
  line-height: 16px;
  margin-bottom: 1px;
}

.much p {
  text-align: center;
  font-size: 14px;
  font-family: 'PingFangSC-Medium', sans-serif;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
}

.reduce-add {
  height: 30px;
  /* border: 1px solid rgba(230, 230, 230, 0.7); */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 74px;
  margin-right: 94px;
}

.reduce-add p,
.reduce-add input {
  height: 30px;
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 30px;
  text-align: center;
  border-left: 0.5px solid rgba(230, 230, 230, 1);
  border-right: 0.5px solid rgba(230, 230, 230, 1);
}

.reduce-add img {
  height: 20px;
  width: 20px;
  margin: 5px;
  cursor: pointer;
}

.common-much {
  width: 83px;
}

.common-much div {
  font-size: 18px;
  color: rgba(41, 127, 171, 1);
  line-height: 18px;
  margin-bottom: 1px;
  text-align: center;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.common-much p {
  font-size: 18px;
  color: rgba(179, 179, 179, 1);
  line-height: 18px;
  font-family: 'PingFangSC-Medium', sans-serif;
  text-align: center;
}

/*确认支付*/
.payment {
  height: 60px;
  margin-bottom: 30px;
  border: 1px solid rgba(182, 182, 182, 0.7);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.all {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
}

.all img {
  height: 17px;
  width: 17px;
  margin-right: 10px;
}

.all p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
}

.payment > p {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 40px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 20px;
}

.payment span {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 2px;
  margin-right: 2px;
}

.payment-botton {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment-botton > div {
  text-align: right;
  margin-right: 20px;
}

.payment-botton div div {
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 16px;
}

.payment-botton div p {
  font-size: 22px;
  /* font-weight: 600; */
  color: rgba(225, 36, 27, 1);
  line-height: 33px;
  letter-spacing: 1px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.payment-botton > p {
  width: 190px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  background: #297fab;
  text-align: center;
}

/*收货地址*/
.order-address {
  padding-top: 10px;
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.address-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.address-title p {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 20px;
}

/* .address-title div {
  width: 108px;
  height: 32px;
  background: rgba(245, 245, 245, 1);
  border-radius: 3px;
  border: 1px solid rgba(204, 204, 204, 0.7);
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 32px;
  text-align: center;
  margin-left: 20px;
} */

.address {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.address img {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

.address .address-detail {
  word-break: break-all;
}

.address > div {
  width: 300px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.address:last-child > div {
  border-bottom: 0;
  padding-bottom: 10px;
}

.address div div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

.address-people {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 14px;
  padding-right: 20px;
}

.address-people.phone {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.address div > p {
  margin-right: 0px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
}

.address-modify {
  font-size: 12px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 12px;
  /* margin-left: 82px; */
}

/*优惠计算*/
.preferential-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 35px;
}

.preferential {
  padding-bottom: 5px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.preferential > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.preferential div > p {
  font-size: 20px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 25px;
}

h3 {
  width: 156px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 25px;
  text-align: right;
  margin-right: 33px;
}

.preferential-add > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 14px;
}

.preferential-add > div:last-child {
  margin-bottom: 0px;
}

.preferential-add div div {
  width: 100px;
  height: 25px;
  border: 1px solid rgba(153, 153, 153, 0.7);
  font-size: 16px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 25px;
  text-align: center;
  margin-right: 13px;
}

.preferential-add div p {
  font-size: 18px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
}

.preferential-money {
  margin-top: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.preferential-money p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(225, 36, 27, 1);
  line-height: 16px;
}

/*支付方式*/
.mode-payment {
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-top: 40px;
}

.log-box {
  height: 165px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.log-box div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 80px;
}

.log-box div:last-child {
  margin-right: 0;
}

.dot {
  height: 20px;
  width: 20px;
}

.logo {
  height: 36px;
  width: 36px;
  margin-left: 15px;
  margin-right: 10px;
}

.log-box div p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  line-height: 20px;
}

.explain {
  font-size: 14px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 46px;
  text-align: center;
}

/*需要开票*/
.invoice-title {
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: rgba(247, 246, 246, 1);
  padding-left: 25px;
  padding-right: 20px;
}

.invoice-title div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.invoice-title img {
  height: 17px;
  width: 17px;
  margin-left: 5px;
  margin-bottom: 1px;
}

.invoice {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-left: 10px;
}

.invoice-blue {
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 24px;
}

.invoice-body {
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.invoice-body img {
  height: 20px;
  width: 20px;
  margin-left: 30px;
  margin-right: 20px;
}

.invoice-body p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 14px;
  margin-right: 25px;
}

.invoice-body div {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.invoice-body div div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
}

.note {
  margin-top: 40px;
  margin-bottom: 60px;
}

.note p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 24px;
  margin-left: 30px;
  margin-bottom: 12px;
}

.note div {
  height: 105px;
  padding: 30px;
  border: 1px solid rgba(182, 182, 182, 0.7);
}

.note textarea {
  width: 100%;
  height: 105px;
}

.item-close {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-top: 45px;
  cursor: pointer;
}

.number-save {
  width: 113px;
  background-color: rgba(156, 192, 210, 1);
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.log-box .balance-value {
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: flex-start;
}

.balance-value p {
  text-align: left;
}

.log-box .balance-value p:last-child {
  font-size: 14px;
  color: rgba(153, 153, 153, 1);
}

.discount-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 25px;
}

.discount-code p {
  font-size: 18px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 20px;
}

.discount-code input {
  width: 300px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 20px;
  padding: 0 20px;
}

.discount-code input::placeholder {
  color: rgba(153, 153, 153, 1);
}

.search_address_box {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
}

.search_address_box .el-input {
  flex: 1;
}

.search_address_box .click {
  width: 108px;
  height: 32px;
  background: rgba(245, 245, 245, 1);
  border-radius: 3px;
  border: 1px solid rgba(204, 204, 204, 0.7);
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 32px;
  text-align: center;
  margin-right: 20px;
}

.order-address .pagination_box {
  margin-top: 15px;
}
</style>